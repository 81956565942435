<template>
  <div
    class="w-full relative border-[1px] h-[3.5rem] rounded-[0.25rem] my-4 flex items-center"
    :class="[
      !isValid
        ? 'border-invalidred error'
        : selected
        ? 'border-primary'
        : 'border-textblack',
    ]"
  >
    <label
      :for="title"
      class="absolute left-[1rem] top-[-0.5rem] bg-white text-xs px-[0.25rem]"
      >{{ title }} {{ symbol }}<sub>{{ sub }}</sub>
    </label>
    <input
      :name="title"
      :id="title"
      type="number"
      :placeholder="$t('e.g. ') + placeholder"
      class="w-full outline-none border-none h-[3rem] pl-[1rem]"
      :min="minimum"
      :max="maximum"
      :step="steps"
      @focus="isSelected"
      @focusout="notSelected"
      @input="$emit('update:modelValue', $event.target.value)"
      :value="
        // modelValue ? modelValue : defaultValue ? defaultValue : modelValue
        modelValue
      "
    />
    <div class="mr-[1.5rem] ml-[0.5rem]">{{ unit }}</div>
  </div>
  <p
    v-if="!isValid && !isEmpty"
    class="text-[#cb142c] text-sm mb-2 mt-[-0.5rem] leading-4 px-4"
  >
    {{ $t("The") }} {{ title }}
    <span class="mx-1 italic">
      {{ symbol }}<sub>{{ sub }}</sub> </span
    >{{ $t("field") }} {{ $t("must be between") }} {{ minimum }}
    {{ $t("and") }} {{ maximum }} {{ $t("liegen") }}
  </p>
  <p
    v-else-if="isEmpty && !isValid"
    class="text-[#cb142c] text-sm mb-2 mt-[-0.5rem] leading-4 px-4"
  >
    {{ $t("The") }} {{ title
    }}<span class="mx-1 italic">
      {{ symbol }}<sub>{{ sub }}</sub></span
    >
    {{ $t("field is required") }}
  </p>
  <p
    v-if="secondErrorMessage && !isValid && !isEmpty"
    class="text-[#cb142c] text-sm mb-2 leading-4 px-4"
  >
    {{ $t("very poor ground condition. please contact Naue's team") }}
  </p>
</template>
<script>
import debounce from "@/util/debounce.js";
export default {
  props: [
    "title",
    "symbol",
    "placeholder",
    "unit",
    "minimum",
    "maximum",
    "steps",
    "modelValue",
    "secondError",
    "sub",
    // "defaultValue",
  ],
  emits: ["update:modelValue", "validity-check"],

  data() {
    return {
      selected: null,
      isValid: true,
      isEmpty: null,
      secondErrorMessage: false,
    };
  },
  watch: {
    modelValue: "validateDebounced",
  },
  methods: {
    isSelected() {
      this.selected = true;
    },
    notSelected() {
      this.selected = false;
    },

    validateInput() {
      if (!this.modelValue) {
        this.isValid = false;
        this.isEmpty = true;
      } else if (
        +this.modelValue < +this.minimum ||
        +this.modelValue > +this.maximum
      ) {
        this.isValid = false;
        this.isEmpty = false;

        if (this.secondError && +this.modelValue < +this.minimum) {
          this.secondErrorMessage = true;
        } else {
          this.secondErrorMessage = false;
        }
      } else {
        this.isValid = true;
      }
      this.$emit("validity-check", this.isValid);
    },
    validateDebounced: debounce(function () {
      this.validateInput();
    }, 150),
  },
};
</script>
