<template>
  <CalculatorCard :title="$t('Naue Expert Advice')" class="min-w-[18.2rem]"
    ><NavboxCards :title="$t('Project Form')"> <FormIcon /></NavboxCards
    ><NavboxCards :title="$t('Appointment Booking')"
      ><BookingIcon
    /></NavboxCards>
  </CalculatorCard>
</template>

<script>
import CalculatorCard from "../layouts/CalculatorCard.vue";
import NavboxCards from "@/layouts/NavboxCards.vue";
import FormIcon from "../layouts/icons/FormIcon.vue";
import BookingIcon from "@/layouts/icons/BookingIcon.vue";
export default {
  components: {
    CalculatorCard,
    NavboxCards,
    FormIcon,
    BookingIcon,
  },
};
</script>
