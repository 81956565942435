<template>
  <div
    class="group p-4 bg-tertiary transition-transform duration-300 ease-out hover:bg-white flex justify-start my-4 hover:shadow-lg items-center gap-4 cursor-pointer"
  >
    <div>
      <slot></slot>
    </div>
    <p class="font-semibold">
      {{ title }}
    </p>
  </div>
</template>
<script>
export default {
  props: ["title"],
};
</script>
