<template>
  <div class="bg-secondary m-auto p-6 min-w-[20rem]">
    <div class="font-bold mb-2">{{ title }}</div>
    <slot> </slot>
  </div>
</template>
<script>
export default {
  props: ["title"],
};
</script>
