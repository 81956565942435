<template>
  <div class="bg-tertiary px-4 py-8 flex justify-center min-w-min">
    <div class="max-w-[100rem]">
      <h1 class="text-primary font-normal text-4xl mb-6">
        {{ $t("Naue Unpaved Road Calculator") }}
      </h1>
      <div class="flex flex-col md:flex-row gap-4">
        <div class="md:w-[60%]">
          <slot name="calculator"> </slot>
        </div>
        <div class="md:w-[40%]">
          <slot name="navbar"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
