<template>
  <div>
    <div class="bg-white p-6 shadow-md mb-4">
      <div class="flex justify-between">
        <h3 class="text-primary font-bold">{{ $t("Navigator") }}</h3>
        <div
          class="transform duration-700"
          :class="{ 'rotate-180': arrowClicked }"
          @click="arrowClickHandler"
        >
          <SelectIcon />
        </div>
      </div>
      <Transition>
        <div v-if="arrowClicked">
          <div class="flex my-4 ml-2 gap-4 group cursor-default">
            <div
              class="border-primary text-primary border-[1px] rounded-full w-[1.5rem] h-6 flex items-center justify-center cursor-pointer group group-hover:bg-primary transition duration-700"
            >
              <svg
                class="fill-primary group-hover:fill-white"
                viewBox="00 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="50" cy="50" r="15" />
              </svg>
            </div>

            <h4 class="font-semibold">{{ $t("Input Parameters") }}</h4>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>
<script>
import SelectIcon from "@/layouts/icons/SelectIcon.vue";

export default {
  components: {
    SelectIcon,
  },
  data() {
    return {
      arrowClicked: true,
    };
  },
  methods: {
    arrowClickHandler() {
      this.arrowClicked = !this.arrowClicked;
    },
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: all 0.7s linear;
  overflow: hidden;
  max-height: 500px;
}

.v-enter-from,
.v-leave-to {
  max-height: 0;
}
</style>
