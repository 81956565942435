import axios from "axios";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { createRouter, createWebHistory } from "vue-router";
import DE from "./locale/de.json";
import EN from "./locale/en.json";

import App from "./App.vue";
import "./assets/tailwind.css";
import CalculatorFormVue from "./components/CalculatorForm.vue";
import CalculatorNavigator from "./components/CalculatorNavigator.vue";
import ResultNavigator from "./components/ResultNavigator.vue";
import ResultSummary from "./components/ResultSummary.vue";
import store from "./store/index.js";
axios.defaults.baseURL =
  process.env.NODE_ENV == "production"
    ? "http://giroud-han-calc-backend-lb-1763336454.eu-central-1.elb.amazonaws.com"
    : process.env.VUE_APP_API_BASE;

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/calculator",
    },
    {
      path: "/calculator",
      components: {
        default: CalculatorFormVue,
        navigator: CalculatorNavigator,
      },
    },
    {
      path: "/resultsummary",
      components: { default: ResultSummary, navigator: ResultNavigator },
    },
    { path: "/:notFound(.*)", redirect: "/calculator" },
  ],
  scrollBehavior(_, _2, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      left: 0,
      top: 0,
    };
  },
});
const app = createApp(App);
app.use(store);

const i18n = createI18n({
  locale: store.state.locale,
  messages: {
    EN: EN,
    DE: DE,
  },
});

app.use(i18n);
app.use(router, axios);

app.mount("#app");
