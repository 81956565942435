<template>
  <image-wraper>
    <img
      :src="require(`../assets/${imageSource}.webp`)"
      class="w-full"
      :alt="imageSource"
      :title="imageSource"
    />
  </image-wraper>
</template>

<script>
import ImageWraper from "./ImageWraper.vue";
export default {
  components: { ImageWraper },

  computed: {
    imageSource() {
      switch (this.$store.state.reinforcmentId) {
        case 1:
        case 2:
          return "secugrid_3030_q1";
        case 3:
          return "secugrid_4040_q1";
        case 4:
          return "secugrid_6060_q1";
        case 5:
          return "secugrid_8080_q1";
        case 6:
          return "combigrid_2020_q1";
        case 7:
          return "combigrid_3030_q1";
        case 8:
          return "combigrid_4040_q1";
        case 9:
          return "combigrid_6060_q1";
        case 10:
          return "combigrid_8080_q1";
        case 11:
          return "secugrid_3030_q6";
        case 12:
          return "secugrid_4040_q6";
        case 13:
          return "secugrid_6060_q6";
        case 14:
          return "secugrid_8080_q6";
        case 15:
        case 16:
          return "combigrid_3030_q6";
        case 17:
        case 18:
          return "combigrid_6060_q6";
        default:
          return "geogrid_types";
      }
    },
  },
};
</script>
