<template>
  <calculator-card class="flex flex-col gap-4">
    <TitleCard :title="$t('Required thickness')" />

    <input-group-card>
      <DynamicImage
        :totalHeight="this.$store.state.unreinforced"
        :numberOfLayers="1"
        :basecourseImage="this.$store.state.typeOfBaseCourseMaterial"
        :unreinforcement="true"
      />
    </input-group-card>
    <input-group-card>
      <DynamicImage
        :totalHeight="this.$store.state.reinforced"
        :numberOfLayers="+this.$store.state.numberOfLayers"
        :basecourseImage="this.$store.state.typeOfBaseCourseMaterial"
        :topLayersText="topLayersText"
      />
    </input-group-card>
  </calculator-card>
  <calculator-card class="mt-4">
    <TitleCard :title="$t('Summary of results')" />
    <input-group-card>
      <ResultText :topLayersText="topLayersText" />
    </input-group-card>
  </calculator-card>
</template>
<script>
import CalculatorCard from "@/layouts/CalculatorCard.vue";
import TitleCard from "@/layouts/TitleCard.vue";

import DynamicImage from "./DynamicImage.vue";
import ResultText from "./ResultText.vue";

import InputGroupCard from "@/layouts/InputGroupCard.vue";

export default {
  components: {
    CalculatorCard,
    TitleCard,
    DynamicImage,
    ResultText,
    InputGroupCard,
  },
  computed: {
    topLayersText() {
      switch (this.$store.state.reinforcmentId) {
        case 1:
        case 6:
          return "Secugrid® 20/20 Q1";
        case 2:
        case 3:
        case 7:
        case 8:
          return "Secugrid® 30/30 Q1";
        case 4:
        case 5:
        case 9:
        case 10:
          return "Secugrid® 40/40 Q1";
        case 11:
        case 12:
        case 15:
        case 16:
          return "Secugrid® 30/30 Q6";
        case 13:
        case 14:
        case 17:
        case 18:
          return "Secugrid® 40/40 Q6";

        default:
          return "";
      }
    },
  },
};
</script>
