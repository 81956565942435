<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="32px"
    height="32px"
  >
    <path
      fill="rgb(43, 173, 112)"
      d="M9.3536 14.6464a.5002.5002 0 0 1 .0578.638l-.0578.0692L5.206 19.5H8a.5.5 0 0 1 .0899.992L8 20.5H4l-.0534-.0028-.0747-.0138-.063-.0212-.076-.0394-.055-.0404-.0315-.0288-.0374-.042-.0423-.0618-.029-.0592-.021-.0625-.0146-.0818L3.5 20v-4a.5.5 0 0 1 .992-.0899L4.5 16l-.001 2.793 4.1474-4.1466a.5.5 0 0 1 .7072 0Zm5.9307-.0578.0693.0578L19.5 18.793V16a.5.5 0 0 1 .992-.0899L20.5 16v4l-.0028.0534-.0138.0747-.0212.063-.0394.076-.0404.055-.0288.0315-.042.0374-.0618.0423-.0793.0369-.0449.0138-.0793.0139L20 20.5h-4a.5.5 0 0 1-.0899-.992L16 19.5h2.793l-4.1466-4.1464a.5.5 0 0 1 .638-.765ZM8 3.5a.5.5 0 0 1 .0899.992L8 4.5l-2.794-.001 4.1476 4.1474a.5.5 0 0 1-.638.765l-.0692-.0578L4.499 5.206 4.5 8a.5.5 0 0 1-.992.0899L3.5 8V4l.0028-.0534.0138-.0747.0212-.063.0394-.076.0404-.055.0288-.0315.042-.0374.0618-.0423.0592-.029.0625-.021.056-.0115L4 3.5h4Zm12 0 .0534.0028.0747.0138.063.0212.076.0394.055.0404.0315.0288.0374.042.0423.0618.029.0592.021.0625.0146.0818L20.5 4v4a.5.5 0 0 1-.992.0899L19.5 8V5.206l-4.1464 4.1476a.5.5 0 0 1-.765-.638l.0578-.0692L18.793 4.499 16 4.5a.5.5 0 0 1-.0899-.992L16 3.5h4Z"
      color="rgb(43, 173, 112)"
    ></path>
  </svg>
</template>
