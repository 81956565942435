<template>
  <div
    v-if="imageFullscreen"
    class="w-screen bg-secondary bg-opacity-90 fixed left-0 top-0 min-h-[100vh] flex items-center justify-center z-50"
    @click="this.imageFullscreen = false"
  >
    <button
      type="button"
      class="absolute top-4 right-4 bg-white rounded-l-lg p-[0.5rem] flex items-center justify-center gap-[0.5rem] w-[5rem] h-[5rem]"
    >
      <ZoomIcon />
    </button>
    <div @click.stop class="w-full md:w-[90%] lg:w-[80%] xl:w-[60%]">
      <slot></slot>
    </div>
  </div>
  <div
    class="flex relative cursor-pointer"
    @click="this.imageFullscreen = true"
  >
    <slot></slot>
    <div
      @mouseover="this.hovered = true"
      @mouseout="this.hovered = false"
      @click="this.imageFullscreen = true"
    >
      <button
        type="button"
        class="absolute bottom-4 right-0 bg-white rounded-l-lg p-[0.5rem] flex items-center gap-[0.5rem]"
      >
        <CloseIcon />
        <p
          v-if="hovered"
          class="pointer-events-none place-items-center text-primary font-semibold transition duration-1000"
        >
          {{ $t("Full screen") }}
        </p>
      </button>
    </div>
  </div>
</template>
<script>
import ZoomIcon from "../layouts/icons/ZoomIcon.vue";
import CloseIcon from "../layouts/icons/CloseIcon.vue";

export default {
  components: { ZoomIcon, CloseIcon },
  data() {
    return {
      hovered: false,
      imageFullscreen: false,
    };
  },
  props: [],
};
</script>
