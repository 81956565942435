<template>
  <div
    class="w-full relative border-[1px] rounded-[0.25rem] my-[1.2rem]"
    :class="[
      pleaseSelect
        ? 'border-invalidred error'
        : selected
        ? 'border-primary'
        : 'border-textblack',
    ]"
  >
    <SelectIcon
      classes="absolute right-[1rem] top-[1rem] pointer-events-none"
    />
    <label
      :for="title"
      class="absolute left-[1rem] top-[-0.5rem] bg-white text-xs px-[0.25rem] whitespace-nowrap"
      :class="{ 'z-50': disabled }"
      >{{ title }}</label
    >
    <select
      :name="title"
      :id="title"
      class="w-full h-[3.5rem] outline-none rounded-lg appearance-none pl-[1rem]"
      :class="{ 'bg-secondary': disabled }"
      @focus="isSelected"
      @focusout="notSelected"
      @change="textSendingHandler"
      :value="modelValue"
      :disabled="disabled"
    >
      <option :value="''" class="checked:bg-primary checked:text-white" hidden>
        {{ $t("Please select") }}
      </option>
      <option
        v-for="option in options"
        :value="option.value"
        :key="option.title"
        :id="option.id"
        class="checked:bg-primary checked:text-white"
      >
        {{ $t(option.title) }}
      </option>
    </select>
  </div>
  <p
    v-if="pleaseSelect"
    class="text-[#cb142c] text-sm mt-[-0.5rem] leading-4 px-[1rem]"
  >
    {{ $t("The") }} {{ title }} {{ $t("field is required") }}
  </p>
</template>
<script>
import SelectIcon from "../layouts/icons/SelectIcon.vue";
export default {
  components: { SelectIcon },
  props: ["options", "title", "modelValue", "disabled"],
  emits: ["update:modelValue", "sendText", "sendId"],
  data() {
    return {
      selected: null,
      pleaseSelect: false,
    };
  },
  watch: {
    modelValue: "optionSelectedCheck",
  },
  methods: {
    textSendingHandler(event) {
      this.$emit("update:modelValue", event.target.value);
      this.$emit(
        "sendText",
        event.target.options[event.target.selectedIndex].text
      );
      this.$emit(
        "sendId",
        +event.target.options[event.target.selectedIndex].id
      );
    },
    isSelected() {
      this.selected = true;
    },
    notSelected() {
      this.selected = false;
    },
    optionSelectedCheck() {
      if (!this.modelValue) {
        this.pleaseSelect = true;
      } else {
        this.pleaseSelect = false;
      }
    },
  },
};
</script>
