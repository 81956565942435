<template>
  <div class="font-bold my-4">
    {{ title }}
  </div>
</template>
<script>
export default {
  props: ["title"],
};
</script>
