<template>
  <div class="flex gap-2">
    <CalculatorIcon />
    <h2>
      {{ title }} {{ symbol }}:
      <span class="font-bold">{{ " " + value }}{{ " " + unit }}</span>
    </h2>
  </div>
</template>
<script>
import CalculatorIcon from "../layouts/icons/CalculatorIcon.vue";
export default {
  components: {
    CalculatorIcon,
  },
  props: ["title", "value", "unit", "symbol"],
};
</script>
