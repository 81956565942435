<template>
  <div>
    <div class="bg-white p-[1.5rem] shadow-md mb-[1rem] min-w-[18.2rem]">
      <div class="flex justify-between">
        <h3 class="text-primary font-bold">{{ $t("Navigator") }}</h3>
        <div
          class="transform duration-700"
          :class="{ 'rotate-180': arrowClicked, '': !arrowClicked }"
          @click="arrowClickHandler"
        >
          <SelectIcon />
        </div>
      </div>
      <Transition>
        <div v-if="arrowClicked">
          <div class="flex flex-col gap-[1rem]">
            <div class="flex p-[0.5rem] gap-[1rem] group cursor-default">
              <div
                class="min-w-[2.5rem] bg-primary border-primary text-white border-[1px] rounded-full w-[2.5rem] h-[2.5rem] flex items-center justify-center cursor-pointer grouptransition duration-700 group-hover:bg-white group relative"
              >
                <div
                  class="border-r-[2px] border-primary h-[1.5rem] absolute bottom-[-1.5rem] cursor-default pointer-events-none"
                ></div>
                <svg
                  class="fill-white group-hover:fill-primary"
                  viewBox="00 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="50" cy="50" r="18" />
                </svg>
              </div>

              <h4 class="pt-[0.5rem]">{{ $t("Base course thickness") }}</h4>
            </div>
            <router-link to="/calculator">
              <div class="flex p-[0.5rem] gap-[1rem] cursor-pointer group">
                <div
                  class="min-w-[1.5rem] border-primary text-primary border-[1px] rounded-full w-[1.5rem] h-[1.5rem] flex items-center justify-center cursor-pointer group-hover:bg-primary transition duration-700 p-[0.1rem] ml-[0.5rem] relative"
                >
                  <div
                    class="border-r-[2px] border-primary h-[1.5rem] absolute top-[-1.5rem] cursor-default pointer-events-none"
                  ></div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="fill-primary group-hover:fill-white"
                    data-v-501ad0e0=""
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>

                <h4>{{ $t("Input Parameters") }}</h4>
              </div>
            </router-link>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>
<script>
import SelectIcon from "@/layouts/icons/SelectIcon.vue";

export default {
  components: {
    SelectIcon,
  },
  data() {
    return {
      arrowClicked: true,
    };
  },
  methods: {
    arrowClickHandler() {
      this.arrowClicked = !this.arrowClicked;
    },
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: all 0.7s linear;
  overflow: hidden;
  max-height: 500px;
}

.v-enter-from,
.v-leave-to {
  max-height: 0;
}
</style>
