<template>
  <form novalidate @submit.prevent="submitHandler" name="unpaved-road">
    <calculator-card :title="$t('Input Parameters')">
      <TitleCard :title="$t('Wheel load')" />
      <group-data-card>
        <image-wraper v-if="!this.typeOfVehicle || this.typeOfVehicle == 1"
          ><div class="bg-white">
            <img
              src="../assets/1.webp"
              class="w-full"
              alt="Single wheel load image"
              title="Single wheel load image"
            />
          </div>
        </image-wraper>
        <image-wraper v-else-if="this.typeOfVehicle == 2"
          ><div class="bg-white">
            <img
              src="../assets/2.webp"
              class="w-full"
              alt="Dual wheel load image"
              title="Dual wheel load image"
            />
          </div>
        </image-wraper>

        <input-group-card>
          <SelectInput
            ref="typeOfVehicleRef"
            :title="$t('Type of vehicle')"
            :options="this.$store.state.vehicles"
            v-model="typeOfVehicle"
          />
          <CustomInput
            ref="axleLoadRef"
            :title="$t('Axle load')"
            symbol="F"
            placeholder="80"
            unit="kN"
            minimum="40"
            maximum="320"
            steps="1"
            v-model="axleLoad"
            @validity-check="axleLoadValidityCheck"
          />

          <CustomInput
            ref="wheelWidthRef"
            :title="$t('Wheel width')"
            symbol="D"
            placeholder="30"
            unit="cm"
            minimum="18"
            :maximum="wheelWidthMaximum"
            steps="1"
            v-model="wheelWidth"
            @validity-check="wheelWidthValidityCheck"
          />

          <CalculatedValue
            :title="$t('Tire contact pressure')"
            symbol="P"
            :value="tireContactPressure"
            unit="kPa"
          />
          <p
            v-if="tireContactPressureNotAcceptable"
            class="text-[#cb142c] text-sm my-2 leading-4 px-4"
          >
            {{
              $t(
                "The inputted D and F values will result in an unrealistic P value."
              )
            }}
          </p>
          <CustomInput
            ref="allowableRutDepthRef"
            :title="$t('Allowable rut depth')"
            symbol="s"
            placeholder="75"
            unit="mm"
            minimum="15"
            maximum="120"
            steps="1"
            v-model="allowableRutDepth"
            @validity-check="allowableRutDepthValidityCheck"
          />
          <WarningMessage
            v-if="75 < allowableRutDepth && allowableRutDepth <= 120"
            >{{
              $t(
                "The selected s value can affect the road serviceability. It is recommended to design with values less than or equal to 75 mm."
              )
            }}</WarningMessage
          >
          <CustomInput
            ref="numberOfAxlePassesRef"
            :title="$t('Number of axle passes')"
            symbol="N"
            placeholder="20,000"
            unit=""
            minimum="500"
            maximum="200000"
            steps="500"
            v-model="numberOfAxlePasses"
            @validity-check="numberOfAxlePassesValidityCheck"
          />
        </input-group-card>
      </group-data-card>
      <div class="flex gap-2 items-center py-4">
        <input
          type="checkbox"
          id="vehiclePropertiesVisibility"
          class="h-[4rem]"
          v-model="vehiclePropertiesVisibility"
        />
        <label for="vehiclePropertiesVisibility">{{
          $t("Calculate with the effect of wheel wandering")
        }}</label>
      </div>
      <div v-if="vehiclePropertiesVisibility">
        <TitleCard :title="$t('Road geometry')" />
        <group-data-card>
          <image-wraper v-if="!this.typeOfVehicle || this.typeOfVehicle == 1">
            <div class="bg-white">
              <img
                src="../assets/5.webp"
                class="w-full"
                alt="Single wheel road image"
                title="Single wheel road image"
              />
            </div>
          </image-wraper>
          <image-wraper v-else-if="this.typeOfVehicle == 2">
            <div class="bg-white">
              <img
                src="../assets/4.webp"
                class="w-full"
                alt="Dual wheel road image"
                title="Dual wheel road image"
              />
            </div>
          </image-wraper>
          <input-group-card>
            <CustomInput
              ref="roadWidthRef"
              :title="$t('Road width')"
              symbol="W"
              placeholder="450"
              unit="cm"
              minimum="250"
              maximum="800"
              steps="10"
              v-model="roadWidth"
              @validity-check="roadWidthValidityCheck"
            />
            <CustomInput
              ref="truckWidthRef"
              :title="$t('Truck width')"
              symbol="B"
              placeholder="250"
              unit="cm"
              minimum="130"
              maximum="450"
              steps="10"
              v-model="truckWidth"
              @validity-check="truckWidthValidityCheck"
            />
            <p
              v-if="roadWidthNotAcceptable"
              class="text-[#cb142c] text-sm my-2 leading-4 px-4"
            >
              {{
                $t("The road width is very narrow for the selected vehicle.")
              }}
            </p>
          </input-group-card>
        </group-data-card>
      </div>
      <TitleCard :title="$t('Material properties')" />
      <group-data-card>
        <input-group-card>
          <div v-if="!this.$store.state.dataFromSolutionFinder">
            <SelectInput
              ref="typeOfSubgradeRef"
              :options="this.$store.state.subgrade"
              :title="$t('Type of subgrade material')"
              v-model="typeOfSubgradeMaterial"
              :disabled="subgradeVisibility"
              @send-id="sendSubgradeId"
            />

            <div class="flex gap-2 items-center py-4">
              <input
                :disabled="!typeOfSubgradeMaterial"
                type="checkbox"
                id="subgradeVisibility"
                class="h-[4rem] accent-primary"
                v-model="subgradeVisibility"
              />
              <label for="subgradeVisibility">{{
                $t("Change the subgrade CBR-value")
              }}</label>
            </div>

            <CustomInput
              ref="subgradeCBRRef"
              v-if="subgradeVisibility && typeOfSubgradeMaterial"
              :title="$t('Subgrade')"
              symbol="CBR"
              sub="SUB"
              placeholder="2"
              unit="%"
              minimum="0.5"
              maximum="20"
              steps="0.5"
              v-model="subgradeCBR"
              @validity-check="subgradeCBRValidityCheck"
              :secondError="true"
            />
          </div>
          <WarningMessage
            v-if="0.5 <= +subgradeComputed && +subgradeComputed < 1"
            >{{
              $t("Poor ground condition. We recommend you use Naue consultancy")
            }}</WarningMessage
          >
          <CalculatedValue
            :title="$t('Subgrade CBR')"
            :value="subgradeComputed"
            unit="%"
          />
          <SelectInput
            ref="typeOfBaseCourseRef"
            :options="this.$store.state.baseCourse"
            :title="$t('Type of base course material')"
            v-model="typeOfBaseCourseMaterial"
          />
        </input-group-card>

        <MultiImages />

        <input-group-card>
          <SelectInput
            ref="typeOfReinforcmentRef"
            :title="$t('Reinforcement material')"
            :options="reinforcementOptions"
            v-model="typeOfReinforcementMaterial"
            @send-text="sendReinforcementMaterialText"
            @send-id="sendReinforcementId"
          />
        </input-group-card>
      </group-data-card>

      <CustomButton :title="$t('calculate')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          class="hd-icon"
        >
          <path
            fill="currentColor"
            d="M20 3a.5.5 0 0 1 .5.5v17a.5.5 0 1 1-1 0v-2H11c0 1.1046-.8954 2-2 2s-2-.8954-2-2H4.5v2a.5.5 0 1 1-1 0v-17a.5.5 0 0 1 1 0v2h1c0-1.1046.8954-2 2-2s2 .8954 2 2h1c0-1.1046.8954-2 2-2s2 .8954 2 2h5v-2A.5.5 0 0 1 20 3ZM9 16.5c-.5128 0-.9355.386-.9933.8834L8 17.5v1c0 .5523.4477 1 1 1 .5128 0 .9355-.386.9933-.8834L10 18.5v-1c0-.5523-.4477-1-1-1Zm-3.5-4h-1v5H7c0-1.1046.8954-2 2-2s2 .8954 2 2h8.5v-5h-1c0 1.1046-.8954 2-2 2s-2-.8954-2-2h-5c0 1.1046-.8954 2-2 2s-2-.8954-2-2Zm11-2c-.5128 0-.9355.386-.9933.8834L15.5 11.5v1c0 .5523.4477 1 1 1 .5128 0 .9355-.386.9933-.8834L17.5 12.5v-1c0-.5523-.4477-1-1-1Zm-9 0c-.5128 0-.9355.386-.9933.8834L6.5 11.5v1c0 .5523.4477 1 1 1 .5128 0 .9355-.386.9933-.8834L8.5 12.5v-1c0-.5523-.4477-1-1-1Zm-2-4h-1v5h1c0-1.1046.8954-2 2-2s2 .8954 2 2h5c0-1.1046.8954-2 2-2s2 .8954 2 2h1v-5h-5c0 1.1046-.8954 2-2 2s-2-.8954-2-2h-1c0 1.1046-.8954 2-2 2s-2-.8954-2-2Zm7-2c-.5128 0-.9355.386-.9933.8834L11.5 5.5v1c0 .5523.4477 1 1 1 .5128 0 .9355-.386.9933-.8834L13.5 6.5v-1c0-.5523-.4477-1-1-1Zm-5 0c-.5128 0-.9355.386-.9933.8834L6.5 5.5v1c0 .5523.4477 1 1 1 .5128 0 .9355-.386.9933-.8834L8.5 6.5v-1c0-.5523-.4477-1-1-1Z"
          ></path>
        </svg>
      </CustomButton>
    </calculator-card>
  </form>
</template>

<script>
import CustomButton from "./CustomButton.vue";
import CustomInput from "./CustomInput.vue";
import CalculatedValue from "./CalculatedValue.vue";
import SelectInput from "./SelectInput.vue";
import ImageWraper from "./ImageWraper.vue";
import InputGroupCard from "../layouts/InputGroupCard.vue";
import TitleCard from "../layouts/TitleCard.vue";
import CalculatorCard from "../layouts/CalculatorCard.vue";
import MultiImages from "./MultiImages.vue";
import WarningMessage from "./WarningMessage.vue";
import GroupDataCard from "@/layouts/GroupDataCard.vue";
import axios from "axios";

export default {
  components: {
    CustomInput,
    CalculatedValue,
    SelectInput,
    CustomButton,
    ImageWraper,
    InputGroupCard,
    TitleCard,
    CalculatorCard,
    MultiImages,
    WarningMessage,
    GroupDataCard,
  },
  data() {
    return {
      form: {
        wheelWidth: "",
        roadWidth: "",
        truckWidth: "",
        axleLoad: "",
        allowableRutDepth: "",
        numberOfAxlePasses: "",
        typeOfSubgradeMaterial: "",
        subgradeCBR: "",
        typeOfBaseCourseMaterial: "",
        typeOfReinforcementMaterial: "",
        typeOfVehicle: "",
        wheelwandering: false,
      },
      // inputs Data:
      wheelWidth: this.$store.state.wheelWidth,
      roadWidth: this.$store.state.roadWidth,
      truckWidth: this.$store.state.truckWidth,
      axleLoad: this.$store.state.axleLoad,
      allowableRutDepth: this.$store.state.allowableRutDepth,
      numberOfAxlePasses: this.$store.state.numberOfAxlePasses,
      subgradeCBR: this.$store.state.subgradeCBR,

      // type of select data:
      typeOfSubgradeMaterial: this.$store.state.typeOfSubgradeMaterial,
      typeOfBaseCourseMaterial: this.$store.state.typeOfBaseCourseMaterial,
      typeOfReinforcementMaterial:
        this.$store.state.typeOfReinforcementMaterial,
      typeOfVehicle: this.$store.state.typeOfVehicle,

      // checkbox is checked or not:
      subgradeVisibility: this.$store.state.subgradeVisibility,
      vehiclePropertiesVisibility:
        this.$store.state.vehiclePropertiesVisibility,
      roadWidthNotAcceptable: false,
      tireContactPressureNotAcceptable: false,
    };
  },
  computed: {
    reinforcementOptions() {
      if (0.5 <= +this.subgradeComputed && +this.subgradeComputed < 1) {
        return this.$store.state.reinforcementMaterial2;
      } else if (1 <= +this.subgradeComputed && +this.subgradeComputed < 2) {
        return this.$store.state.reinforcementMaterial3;
      } else if (2 <= +this.subgradeComputed && +this.subgradeComputed < 3) {
        return this.$store.state.reinforcementMaterial4;
      } else return this.$store.state.reinforcementMaterial;
    },
    tireContactPressure() {
      if (!this.axleLoad || !this.wheelWidth || !this.typeOfVehicle) {
        return 0;
      } else {
        if (+this.typeOfVehicle === 1) {
          return (
            this.axleLoad /
            2 /
            (Math.PI * ((this.wheelWidth / 2) * 0.01) ** 2)
          ).toFixed(1);
        } else {
          return (
            (this.axleLoad * 1.3) /
            4 /
            (Math.PI * ((this.wheelWidth / 2) * 0.01) ** 2)
          ).toFixed(1);
        }
      }
    },
    subgradeComputed() {
      if (this.$store.state.dataFromSolutionFinder) {
        return this.$store.state.CBRFromSolutionFinder;
      } else if (
        !this.subgradeVisibility ||
        (this.subgradeVisibility &&
          this.typeOfSubgradeMaterial &&
          !this.subgradeCBR)
      ) {
        return +this.typeOfSubgradeMaterial;
      } else if (this.subgradeVisibility && this.subgradeCBR) {
        return this.subgradeCBR;
      } else return 0;
    },
    wheelWidthMaximum() {
      if (this.typeOfVehicle == 2) {
        return 80;
      } else {
        return 120;
      }
    },
  },

  methods: {
    //checking the validity of tire contact pressure

    tireContactPressureTest() {
      if (this.tireContactPressure < 100 || this.tireContactPressure > 850) {
        this.tireContactPressureNotAcceptable = true;
      } else {
        this.tireContactPressureNotAcceptable = false;
      }
    },
    // checking the validity of the road with based on total of truck and wheel width
    roadWidthTest() {
      if (
        this.$store.state.wheelWidthIsValid &&
        this.$store.state.truckWidthIsValid &&
        this.$store.state.roadWidthIsvalid &&
        (+this.wheelWidth * 2 + +this.truckWidth > +this.roadWidth ||
          (this.typeOfVehicle == 2 &&
            +this.wheelWidth * 4 + +this.truckWidth > +this.roadWidth))
      ) {
        this.roadWidthNotAcceptable = true;
      } else {
        this.roadWidthNotAcceptable = false;
      }
    },
    // checking the validity of the inputs with validy check emit:
    wheelWidthValidityCheck(data) {
      this.$store.dispatch({ type: "setWheelWidthIsValid", value: data });
      this.roadWidthTest();
      this.tireContactPressureTest();
    },
    roadWidthValidityCheck(data) {
      this.$store.dispatch({ type: "setRoadWidthIsvalid", value: data });
      this.roadWidthTest();
    },
    truckWidthValidityCheck(data) {
      this.$store.dispatch({ type: "setTruckWidthIsValid", value: data });

      this.roadWidthTest();
    },
    axleLoadValidityCheck(data) {
      this.$store.dispatch({ type: "setAxleLoadIsValid", value: data });
      this.tireContactPressureTest();
    },
    allowableRutDepthValidityCheck(data) {
      this.$store.dispatch({
        type: "setAllowableRutDepthIsValid",
        value: data,
      });
    },
    numberOfAxlePassesValidityCheck(data) {
      this.$store.dispatch({
        type: "setNumberOfAxlePassesIsvalid",
        value: data,
      });
    },
    subgradeCBRValidityCheck(data) {
      this.$store.dispatch({
        type: "setSubgradeCBRIsValid",
        value: data,
      });
    },

    //sending texts of inputs

    sendReinforcementMaterialText(data) {
      this.$store.dispatch({
        type: "setReinforcementMaterialText",
        value: data,
      });
    },

    sendSubgradeId(data) {
      this.$store.dispatch({
        type: "setsubgradeId",
        value: data,
      });
    },
    sendReinforcementId(data) {
      this.$store.dispatch({
        type: "setReinforcementId",
        value: data,
      });
    },

    submitHandler() {
      if (
        // checking the validation of all the inputs:
        (!this.vehiclePropertiesVisibility ||
          (this.vehiclePropertiesVisibility &&
            !this.roadWidthNotAcceptable &&
            this.$store.state.roadWidthIsvalid &&
            this.$store.state.truckWidthIsValid)) &&
        !this.tireContactPressureNotAcceptable &&
        this.$store.state.axleLoadIsValid &&
        this.$store.state.allowableRutDepthIsValid &&
        this.$store.state.wheelWidthIsValid &&
        this.typeOfVehicle &&
        this.$store.state.numberOfAxlePassesIsvalid &&
        this.typeOfSubgradeMaterial &&
        this.typeOfBaseCourseMaterial &&
        this.typeOfReinforcementMaterial &&
        (!this.subgradeVisibility || this.$store.state.subgradeCBRIsValid)
      ) {
        this.$store.dispatch({
          type: "setSubgradeVisibility",
          value: this.subgradeVisibility,
        });
        this.$store.dispatch({
          type: "setVehiclePropertiesVisibility",
          value: this.vehiclePropertiesVisibility,
        });
        this.$store.dispatch({ type: "setWheelWidth", value: this.wheelWidth });
        this.$store.dispatch({ type: "setRoadWidth", value: this.roadWidth });
        this.$store.dispatch({ type: "setTruckWidth", value: this.truckWidth });
        this.$store.dispatch({ type: "setAxleLoad", value: this.axleLoad });
        this.$store.dispatch({
          type: "setAllowableRutDepth",
          value: this.allowableRutDepth,
        });
        this.$store.dispatch({
          type: "setNumberOfAxlePasses",
          value: this.numberOfAxlePasses,
        });
        this.$store.dispatch({
          type: "setSubgradeCBR",
          value: this.subgradeCBR,
        });
        this.$store.dispatch({
          type: "setTypeOfSubgradeMaterial",
          value: this.typeOfSubgradeMaterial,
        });
        this.$store.dispatch({
          type: "setTypeOfBaseCourseMaterial",
          value: this.typeOfBaseCourseMaterial,
        });
        this.$store.dispatch({
          type: "setTypeOfReinforcementMaterial",
          value: this.typeOfReinforcementMaterial,
        });
        this.$store.dispatch({
          type: "setTypeOfVehicle",
          value: this.typeOfVehicle,
        });
        this.form.roadWidth = +this.$store.state.roadWidth;
        this.form.truckWidth = +this.$store.state.truckWidth;
        this.form.wheelWidth = +this.$store.state.wheelWidth;
        this.form.axleLoad = +this.$store.state.axleLoad;
        this.form.allowableRutDepth = +this.$store.state.allowableRutDepth;
        this.form.numberOfAxlePasses = +this.$store.state.numberOfAxlePasses;
        this.form.typeOfSubgradeMaterial =
          this.$store.state.typeOfSubgradeMaterial;
        this.form.subgradeCBR = +this.subgradeComputed;
        this.form.typeOfBaseCourseMaterial =
          this.$store.state.typeOfBaseCourseMaterial;
        this.form.typeOfReinforcementMaterial =
          this.$store.state.typeOfReinforcementMaterial;
        this.form.wheelwandering =
          this.$store.state.vehiclePropertiesVisibility;
        this.form.typeOfVehicle = this.$store.state.typeOfVehicle;

        axios
          .post("/api/submit/", this.form)
          .then((response) => {
            if (response.data.message === "success") {
              // Access the result from the response data
              const reinforced = response.data.reinforced;
              const unreinforced = response.data.unreinforced;
              const numberOfLayers = response.data.numberOfLayers;

              // Use the result as needed
              this.$store.dispatch({
                type: "setHeightOfLayers",
                value: +((reinforced * 400) / numberOfLayers).toFixed(0) + "px",
              });
              this.$store.dispatch({
                type: "setHeightOfUnreinforcementLayer",
                value: +(unreinforced * 400).toFixed(0) + "px",
              });
              this.$store.dispatch({
                type: "setHeightOfReinforcementLayer",
                value: +(reinforced * 400).toFixed(0) + "px",
              });
              this.$store.dispatch({
                type: "setNumberOfLayers",
                value: numberOfLayers,
              });
              this.$store.dispatch({
                type: "setReinforced",
                value: Math.round(reinforced * 100),
              });

              this.$store.dispatch({
                type: "setUnreinforced",
                value: Math.round(unreinforced * 100),
              });
            } else {
              console.log("Error: Something went wrong on the server side");
            }
          })
          .catch((error) => {
            console.log("Error:", error);
          });

        this.$router.push("/resultsummary");
      } else {
        // check for errors for inputs:

        this.$refs.axleLoadRef.validateInput();
        this.$refs.allowableRutDepthRef.validateInput();
        this.$refs.numberOfAxlePassesRef.validateInput();
        this.$refs.wheelWidthRef.validateInput();
        // check for errors for select options:

        this.$refs.typeOfReinforcmentRef.optionSelectedCheck();
        this.$refs.typeOfBaseCourseRef.optionSelectedCheck();
        this.$refs.typeOfSubgradeRef.optionSelectedCheck();
        this.$refs.typeOfVehicleRef.optionSelectedCheck();
        // check for error of CBR if the checkbox is checked:

        if (this.subgradeVisibility) {
          this.$refs.subgradeCBRRef.validateInput();
        }
        if (this.vehiclePropertiesVisibility) {
          this.$refs.roadWidthRef.validateInput();
          this.$refs.truckWidthRef.validateInput();
        }

        // scrolling to the first invalid input

        setTimeout(() => {
          let error = document.querySelector(".error");
          if (error) {
            error.scrollIntoView({
              block: "center",
              behavior: "smooth",
            });
          } else if (this.tireContactPressureNotAcceptable) {
            this.$refs.wheelWidthRef.$el.nextElementSibling.scrollIntoView({
              block: "center",
              behavior: "smooth",
            });
          } else if (this.roadWidthNotAcceptable) {
            this.$refs.truckWidthRef.$el.nextElementSibling.scrollIntoView({
              block: "center",
              behavior: "smooth",
            });
          }
        }, 0);
      }
    },
    resetReinforcement() {
      let flag = false;
      this.reinforcementOptions.forEach((item) => {
        if (item.value == this.typeOfReinforcementMaterial) {
          flag = true;
        }
      });
      if (!flag) {
        this.typeOfReinforcementMaterial = "";
      }
    },
  },
  watch: {
    subgradeComputed: "resetReinforcement",
    typeOfVehicle() {
      this.roadWidthTest();
      this.tireContactPressureTest();
      this.$refs.wheelWidthRef.validateInput();

      if (
        this.typeOfVehicle == 2 &&
        80 < +this.wheelWidth &&
        this.wheelWidth < 120
      ) {
        this.wheelWidth = null;
      } else if (this.wheelWidth) {
        this.wheelWidth = +this.wheelWidth;
      }
    },
  },
};
</script>
<style>
input[type="checkbox"] {
  appearance: none;
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border-radius: 4px;
  border: #2bad70 solid 1px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  transition: 0.3s all ease-in-out;
}
input[type="checkbox"]:checked {
  background-color: white;
}
input[type="checkbox"]:disabled {
  background-color: #edefec;
}
input[type="checkbox"]::before {
  position: absolute;
  content: "\2713";
  font-size: 1.5rem;
  line-height: 1px;
  color: #2bad70;
  transform: scale(0);
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>
