import { createStore } from "vuex";
const store = createStore({
  state() {
    return {
      //Result
      reinforced: null,
      unreinforced: null,
      numberOfLayers: 0,
      heightOfLayers: "",
      heightOfUnreinforcementLayer: "",
      heightOfReinforcementLayer: "",

      //input data
      wheelWidth: null,
      roadWidth: null,
      truckWidth: null,
      axleLoad: null,
      allowableRutDepth: null,
      numberOfAxlePasses: null,
      subgradeCBR: null,
      typeOfSubgradeMaterial: null,
      typeOfBaseCourseMaterial: null,
      typeOfReinforcementMaterial: null,
      typeOfVehicle: null,

      // validity of the inputs:

      wheelWidthIsValid: null,
      roadWidthIsvalid: null,
      truckWidthIsValid: null,
      axleLoadIsValid: null,
      allowableRutDepthIsValid: null,
      numberOfAxlePassesIsvalid: null,
      subgradeCBRIsValid: null,

      //global data
      locale: "EN",
      subgradeVisibility: false,
      vehiclePropertiesVisibility: false,
      dataFromSolutionFinder: false,
      CBRFromSolutionFinder: 7,

      //select inputs text:

      reinforcementMaterialText: "",

      //select input ID:

      subgradeId: null,
      reinforcmentId: null,

      //select options data:

      subgrade: [
        { title: "Peat", value: 1, id: 1 },
        { title: "Clay (Soft)", value: 2, id: 2 },
        { title: "Clay (Medium)", value: 4, id: 3 },
        { title: "Clay (Hard)", value: 6, id: 4 },
        { title: "Silt", value: 3, id: 5 },
        { title: "Sand (Loose)", value: 2, id: 6 },
        { title: "Sand (Medium Dense)", value: 5, id: 7 },
        { title: "Sand (Dense)", value: 10, id: 8 },
      ],
      baseCourse: [
        { title: "Sand, e.g 0/2 mm", value: 0.2 },
        { title: "Stone chipping, e.g 0/8 mm", value: 0.8 },
        {
          title: "Gravelly sand with partially crushed grains, e.g 0/32 mm",
          value: 0.32,
        },
        { title: "Crushed stones, e.g 0/45 mm", value: 0.45 },
        { title: "Crushed stones, e.g 0/63 mm", value: 0.63 },
        { title: "Crushed stones, e.g 0/75 mm", value: 0.75 },
      ],
      reinforcementMaterial: [
        {
          title: "Secugrid® 20/20 Q1",
          value: "Secugrid 20/20 Q1",
          id: 1,
        },
        {
          title: "Secugrid® 30/30 Q1",
          value: "Secugrid 30/30 Q1",
          id: 2,
        },
        {
          title: "Secugrid® 40/40 Q1",
          value: "Secugrid 40/40 Q1",
          id: 3,
        },
        {
          title: "Secugrid® 60/60 Q1",
          value: "Secugrid 60/60 Q1",
          id: 4,
        },
        {
          title: "Secugrid® 80/80 Q1",
          value: "Secugrid 80/80 Q1",
          id: 5,
        },
        {
          title: "Combigrid® 20/20 Q1 GRK 4 C",
          value: "Combigrid 20/20 Q1 GRK 4 C",
          id: 6,
        },
        {
          title: "Combigrid® 30/30 Q1 GRK 4 C",
          value: "Combigrid 30/30 Q1 GRK 4 C",
          id: 7,
        },
        {
          title: "Combigrid® 40/40 Q1 GRK 4 C",
          value: "Combigrid 40/40 Q1 GRK 4 C",
          id: 8,
        },
        {
          title: "Combigrid® 60/60 Q1 GRK 4 C",
          value: "Combigrid 60/60 Q1 GRK 4 C",
          id: 9,
        },
        {
          title: "Combigrid® 80/80 Q1 GRK 4 C",
          value: "Combigrid 80/80 Q1 GRK 4 C",
          id: 10,
        },
        {
          title: "Secugrid® 30/30 Q6",
          value: "Secugrid 30/30 Q6",
          id: 11,
        },
        {
          title: "Secugrid® 40/40 Q6",
          value: "Secugrid 40/40 Q6",
          id: 12,
        },
        {
          title: "Secugrid® 60/60 Q6",
          value: "Secugrid 60/60 Q6",
          id: 13,
        },
        {
          title: "Secugrid® 80/80 Q6",
          value: "Secugrid 80/80 Q6",
          id: 14,
        },
        {
          title: "Combigrid® 30/30 Q6 R 156 C",
          value: "Combigrid 30/30 Q6 R 156 C",
          id: 15,
        },
        {
          title: "Combigrid® 40/40 Q6 R 156 C",
          value: "Combigrid 40/40 Q6 R 156 C",
          id: 16,
        },
        {
          title: "Combigrid® 60/60 Q6 R 156 C",
          value: "Combigrid 60/60 Q6 R 156 C",
          id: 17,
        },
        {
          title: "Combigrid® 80/80 Q6 R 156 C",
          value: "Combigrid 80/80 Q6 R 156 C",
          id: 18,
        },
      ],
      reinforcementMaterial2: [
        {
          title: "Combigrid® 40/40 Q1 GRK 4 C",
          value: "Combigrid 40/40 Q1 GRK 4 C",
          id: 8,
        },
        {
          title: "Combigrid® 60/60 Q1 GRK 4 C",
          value: "Combigrid 60/60 Q1 GRK 4 C",
          id: 9,
        },
        {
          title: "Combigrid® 80/80 Q1 GRK 4 C",
          value: "Combigrid 80/80 Q1 GRK 4 C",
          id: 10,
        },
        {
          title: "Combigrid® 40/40 Q6 R 156 C",
          value: "Combigrid 40/40 Q6 R 156 C",
          id: 16,
        },
        {
          title: "Combigrid® 60/60 Q6 R 156 C",
          value: "Combigrid 60/60 Q6 R 156 C",
          id: 17,
        },
        {
          title: "Combigrid® 80/80 Q6 R 156 C",
          value: "Combigrid 80/80 Q6 R 156 C",
          id: 18,
        },
      ],
      reinforcementMaterial3: [
        {
          title: "Combigrid® 30/30 Q1 GRK 4 C",
          value: "Combigrid 30/30 Q1 GRK 4 C",
          id: 7,
        },
        {
          title: "Combigrid® 40/40 Q1 GRK 4 C",
          value: "Combigrid 40/40 Q1 GRK 4 C",
          id: 8,
        },
        {
          title: "Combigrid® 60/60 Q1 GRK 4 C",
          value: "Combigrid 60/60 Q1 GRK 4 C",
          id: 9,
        },
        {
          title: "Combigrid® 80/80 Q1 GRK 4 C",
          value: "Combigrid 80/80 Q1 GRK 4 C",
          id: 10,
        },
        {
          title: "Combigrid® 30/30 Q6 R 156 C",
          value: "Combigrid 30/30 Q6 R 156 C",
          id: 15,
        },
        {
          title: "Combigrid® 40/40 Q6 R 156 C",
          value: "Combigrid 40/40 Q6 R 156 C",
          id: 16,
        },
        {
          title: "Combigrid® 60/60 Q6 R 156 C",
          value: "Combigrid 60/60 Q6 R 156 C",
          id: 17,
        },
        {
          title: "Combigrid® 80/80 Q6 R 156 C",
          value: "Combigrid 80/80 Q6 R 156 C",
          id: 18,
        },
      ],
      reinforcementMaterial4: [
        {
          title: "Combigrid® 20/20 Q1 GRK 4 C",
          value: "Combigrid 20/20 Q1 GRK 4 C",
          id: 6,
        },
        {
          title: "Combigrid® 30/30 Q1 GRK 4 C",
          value: "Combigrid 30/30 Q1 GRK 4 C",
          id: 7,
        },
        {
          title: "Combigrid® 40/40 Q1 GRK 4 C",
          value: "Combigrid 40/40 Q1 GRK 4 C",
          id: 8,
        },
        {
          title: "Combigrid® 60/60 Q1 GRK 4 C",
          value: "Combigrid 60/60 Q1 GRK 4 C",
          id: 9,
        },
        {
          title: "Combigrid® 80/80 Q1 GRK 4 C",
          value: "Combigrid 80/80 Q1 GRK 4 C",
          id: 10,
        },

        {
          title: "Combigrid® 30/30 Q6 R 156 C",
          value: "Combigrid 30/30 Q6 R 156 C",
          id: 15,
        },
        {
          title: "Combigrid® 40/40 Q6 R 156 C",
          value: "Combigrid 40/40 Q6 R 156 C",
          id: 16,
        },
        {
          title: "Combigrid® 60/60 Q6 R 156 C",
          value: "Combigrid 60/60 Q6 R 156 C",
          id: 17,
        },
        {
          title: "Combigrid® 80/80 Q6 R 156 C",
          value: "Combigrid 80/80 Q6 R 156 C",
          id: 18,
        },
      ],
      vehicles: [
        { title: "Single wheel", value: 1 },
        { title: "Dual wheel", value: 2 },
      ],
    };
  },
  mutations: {
    setHeightOfReinforcementLayer(state, payload) {
      state.heightOfReinforcementLayer = payload.value;
    },
    setHeightOfUnreinforcementLayer(state, payload) {
      state.heightOfUnreinforcementLayer = payload.value;
    },
    setHeightOfLayers(state, payload) {
      state.heightOfLayers = payload.value;
    },
    setNumberOfLayers(state, payload) {
      state.numberOfLayers = payload.value;
    },
    setReinforced(state, payload) {
      state.reinforced = payload.value;
    },
    setUnreinforced(state, payload) {
      state.unreinforced = payload.value;
    },
    setWheelWidth(state, payload) {
      state.wheelWidth = payload.value;
    },
    setRoadWidth(state, payload) {
      state.roadWidth = payload.value;
    },
    setTruckWidth(state, payload) {
      state.truckWidth = payload.value;
    },
    setAxleLoad(state, payload) {
      state.axleLoad = payload.value;
    },
    setAllowableRutDepth(state, payload) {
      state.allowableRutDepth = payload.value;
    },
    setNumberOfAxlePasses(state, payload) {
      state.numberOfAxlePasses = payload.value;
    },
    setSubgradeCBR(state, payload) {
      state.subgradeCBR = payload.value;
    },
    setTypeOfSubgradeMaterial(state, payload) {
      state.typeOfSubgradeMaterial = payload.value;
    },
    setTypeOfBaseCourseMaterial(state, payload) {
      state.typeOfBaseCourseMaterial = payload.value;
    },
    setTypeOfReinforcementMaterial(state, payload) {
      state.typeOfReinforcementMaterial = payload.value;
    },
    setTypeOfVehicle(state, payload) {
      state.typeOfVehicle = payload.value;
    },
    setReinforcementId(state, payload) {
      state.reinforcmentId = payload.value;
    },

    //validity mutations

    setWheelWidthIsValid(state, payload) {
      state.wheelWidthIsValid = payload.value;
    },
    setRoadWidthIsvalid(state, payload) {
      state.roadWidthIsvalid = payload.value;
    },
    setTruckWidthIsValid(state, payload) {
      state.truckWidthIsValid = payload.value;
    },
    setAxleLoadIsValid(state, payload) {
      state.axleLoadIsValid = payload.value;
    },
    setAllowableRutDepthIsValid(state, payload) {
      state.allowableRutDepthIsValid = payload.value;
    },
    setNumberOfAxlePassesIsvalid(state, payload) {
      state.numberOfAxlePassesIsvalid = payload.value;
    },
    setSubgradeCBRIsValid(state, payload) {
      state.subgradeCBRIsValid = payload.value;
    },
    setSubgradeVisibility(state, payload) {
      state.subgradeVisibility = payload.value;
    },
    setVehiclePropertiesVisibility(state, payload) {
      state.vehiclePropertiesVisibility = payload.value;
    },
    setReinforcementMaterialText(state, payload) {
      state.reinforcementMaterialText = payload.value;
    },
    setsubgradeId(state, payload) {
      state.subgradeId = payload.value;
    },
  },
  actions: {
    setHeightOfReinforcementLayer(context, payload) {
      context.commit("setHeightOfReinforcementLayer", payload);
    },
    setHeightOfUnreinforcementLayer(context, payload) {
      context.commit("setHeightOfUnreinforcementLayer", payload);
    },
    setHeightOfLayers(context, payload) {
      context.commit("setHeightOfLayers", payload);
    },
    setNumberOfLayers(context, payload) {
      context.commit("setNumberOfLayers", payload);
    },
    setReinforced(context, payload) {
      context.commit("setReinforced", payload);
    },
    setUnreinforced(context, payload) {
      context.commit("setUnreinforced", payload);
    },
    setWheelWidth(context, payload) {
      context.commit("setWheelWidth", payload);
    },
    setRoadWidth(context, payload) {
      context.commit("setRoadWidth", payload);
    },
    setTruckWidth(context, payload) {
      context.commit("setTruckWidth", payload);
    },
    setAxleLoad(context, payload) {
      context.commit("setAxleLoad", payload);
    },
    setAllowableRutDepth(context, payload) {
      context.commit("setAllowableRutDepth", payload);
    },
    setNumberOfAxlePasses(context, payload) {
      context.commit("setNumberOfAxlePasses", payload);
    },
    setSubgradeCBR(context, payload) {
      context.commit("setSubgradeCBR", payload);
    },
    setTypeOfSubgradeMaterial(context, payload) {
      context.commit("setTypeOfSubgradeMaterial", payload);
    },
    setTypeOfBaseCourseMaterial(context, payload) {
      context.commit("setTypeOfBaseCourseMaterial", payload);
    },
    setTypeOfReinforcementMaterial(context, payload) {
      context.commit("setTypeOfReinforcementMaterial", payload);
    },
    setTypeOfVehicle(context, payload) {
      context.commit("setTypeOfVehicle", payload);
    },
    setWheelWidthIsValid(context, payload) {
      context.commit("setWheelWidthIsValid", payload);
    },
    setRoadWidthIsvalid(context, payload) {
      context.commit("setRoadWidthIsvalid", payload);
    },
    setTruckWidthIsValid(context, payload) {
      context.commit("setTruckWidthIsValid", payload);
    },
    setAxleLoadIsValid(context, payload) {
      context.commit("setAxleLoadIsValid", payload);
    },
    setAllowableRutDepthIsValid(context, payload) {
      context.commit("setAllowableRutDepthIsValid", payload);
    },
    setNumberOfAxlePassesIsvalid(context, payload) {
      context.commit("setNumberOfAxlePassesIsvalid", payload);
    },
    setSubgradeCBRIsValid(context, payload) {
      context.commit("setSubgradeCBRIsValid", payload);
    },
    setSubgradeVisibility(context, payload) {
      context.commit("setSubgradeVisibility", payload);
    },
    setVehiclePropertiesVisibility(context, payload) {
      context.commit("setVehiclePropertiesVisibility", payload);
    },
    setReinforcementMaterialText(context, payload) {
      context.commit("setReinforcementMaterialText", payload);
    },
    setsubgradeId(context, payload) {
      context.commit("setsubgradeId", payload);
    },
    setReinforcementId(context, payload) {
      context.commit("setReinforcementId", payload);
    },
  },
  getters: {
    finalWheelWidth(state) {
      return state.wheelWidth;
    },
  },
});
export default store;
