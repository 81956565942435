<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="30"
    class="text-primary"
    data-v-78b3efc6=""
  >
    <path
      fill="currentColor"
      d="m6.284 5.589.07.057L12 11.293l5.646-5.647a.5.5 0 0 1 .765.638l-.057.07L12.707 12l5.647 5.646a.5.5 0 0 1-.638.765l-.07-.057L12 12.707l-5.646 5.647a.5.5 0 0 1-.765-.638l.057-.07L11.293 12 5.646 6.354a.5.5 0 0 1 .638-.765z"
    ></path>
  </svg>
</template>
