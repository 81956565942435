<template>
  <div
    class="group border-[1px] border-primary rounded-[0.7rem] p-[1rem] group-hover:bg-primary"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="30"
      class="hd-icon"
      data-v-042d627d=""
    >
      <path
        class="group-hover:fill-white"
        fill="rgb(43, 173, 112)"
        d="M16 2.5a.5002.5002 0 0 1 .492.4101L16.5 3v1.5H19c1.3255 0 2.41 1.0315 2.4947 2.3356L21.5 7v12c0 1.3807-1.1193 2.5-2.5 2.5H5c-1.3807 0-2.5-1.1193-2.5-2.5V7c0-1.3807 1.1193-2.5 2.5-2.5h2.5V3a.5.5 0 0 1 .992-.0899L8.5 3v1.5h7V3a.5.5 0 0 1 .5-.5Zm-8.5 3H5c-.8284 0-1.5.6716-1.5 1.5v12c0 .8284.6716 1.5 1.5 1.5h14c.8285 0 1.5-.6715 1.5-1.5V7c0-.8284-.6716-1.5-1.5-1.5h-2.5V7a.5.5 0 0 1-.992.0899L15.5 7V5.5h-7V7a.5.5 0 0 1-.992.0899L7.5 7V5.5Zm9.5 5a.5.5 0 0 1 .0899.992L17 11.5H7a.5.5 0 0 1-.0899-.992L7 10.5h10Z"
      ></path>
    </svg>
  </div>
</template>
