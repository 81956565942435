<template>
  <div class="bg-white p-[1rem] w-full mb-[2rem] min-w-[20rem]">
    <p v-if="unreinforcement" class="font-semibold">
      {{ $t("Unreinforced thickness:") }}
    </p>
    <p v-else class="font-semibold">{{ $t("Reinforced thickness:") }}</p>
    <p class="text-sm">
      {{ $t("Well graded") }}
      <span v-if="basecourseImage === '0.2'">{{ $t("Sand") }}</span>
      <span v-else-if="basecourseImage === '0.8'">{{
        $t("Stone chipping")
      }}</span>
      <span v-else-if="basecourseImage === '0.32'">{{
        $t("Gravelly sand with partially crushed grains")
      }}</span>
      <span
        v-else-if="
          basecourseImage === '0.45' ||
          basecourseImage === '0.63' ||
          basecourseImage === '0.75'
        "
        >{{ $t("Crushed stones") }}</span
      >
    </p>
    <p>{{ $t("Recommend grain size distribution:") }}</p>
    <p>
      <span v-if="basecourseImage === '0.2'">0/2</span>
      <span v-else-if="basecourseImage === '0.8'">0/8</span>
      <span v-else-if="basecourseImage === '0.32'">0/32</span>
      <span v-else-if="basecourseImage === '0.45'">0/45</span>
      <span v-else-if="basecourseImage === '0.63'">0/63</span>
      <span v-else-if="basecourseImage === '0.75'">0/75</span>

      mm
    </p>
    <div class="flex">
      <div class="w-[40%] lg:w-[50%]">
        <div class="totalHeight">
          <div
            :class="{
              'bg-[url(../assets/8.webp)]': basecourseImage === '0.2',
              'bg-[url(../assets/9.webp)]': basecourseImage === '0.8',
              'bg-[url(../assets/10.webp)]': basecourseImage === '0.32',
              'bg-[url(../assets/11.webp)]':
                basecourseImage === '0.45' ||
                basecourseImage === '0.63' ||
                basecourseImage === '0.75',
            }"
          >
            <div v-if="!unreinforcement">
              <div
                class="relative"
                v-for="number in numberOfLayers"
                :key="number"
              >
                <div
                  class="w-full layerHeights flex items-end border-b-2 border-primary relative"
                >
                  <div
                    class="border-b-[6px] border-primary border-dotted w-full absolute bottom-[-4px] left-0"
                  ></div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="w-full totalHeight"></div>
            </div>
          </div>
        </div>
        <div :class="'h-[100px]'">
          <div
            class="h-[100%]"
            :class="{
              'bg-[url(../assets/clay.webp)]':
                2 <= +this.$store.state.subgradeId <= 4,
              'bg-[url(../assets/peat.webp)]':
                +this.$store.state.subgradeId === 1,
              'bg-[url(../assets/silt.webp)]':
                +this.$store.state.subgradeId === 5,
              'bg-[url(../assets/sand.webp)]':
                6 <= +this.$store.state.subgradeId,
            }"
          ></div>
        </div>
      </div>
      <div class="w-[60%] lg:w-[50%] ml-[1rem] text-xs">
        <div class="flex items-center totalHeight">
          <div
            v-if="!unreinforcement"
            class="border-b-[2px] border-t-[2px] border-black w-[10%] h-[100%]"
          >
            <div
              v-for="number in numberOfLayers"
              :key="number"
              class="border-b-[2px] border-black layerHeights"
            >
              <div class="h-full w-1/2 border-r-[2px] border-black"></div>
            </div>
          </div>
          <div
            v-else
            class="border-b-[2px] border-t-[2px] border-black w-[10%] h-[100%]"
          >
            <div class="border-b-[2px] border-black totalHeight">
              <div class="h-full w-1/2 border-r-[2px] border-black"></div>
            </div>
            <div>
              <div class="h-full w-1/2 border-r-[2px] border-black"></div>
            </div>
          </div>
          <div
            class="flex flex-col justify-around h-full pl-[0.5rem] w-[90%] totalHeights"
          >
            <div
              v-for="number in numberOfLayers"
              :key="number"
              class="flex flex-col grow mb-[-0.4rem] mt-[0.4rem] items-start layerHeights"
            >
              <div class="flex-1 flex place-items-center justify-center">
                {{ (totalHeight / numberOfLayers).toFixed(1) }} cm
              </div>
              <div v-if="!unreinforcement" class="flex-none whitespace-nowrap">
                <div v-if="number != numberOfLayers">
                  {{ topLayersText }}
                </div>
                <div v-else>
                  {{ this.$store.state.reinforcementMaterialText }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="'h-[100px]'" class="flex items-center">
          <div class="border-b-[2px] border-black w-[10%] h-[100%]">
            <div class="border-r-[2px] border-black h-[100%] w-1/2"></div>
          </div>
          <div class="w-[90%]">
            <p>{{ $t("In-situ subgrade") }}</p>
            <p>
              {{ $t("min. CBR") }} &ge;{{
                this.$store.state.subgradeCBR ||
                this.$store.state.typeOfSubgradeMaterial
              }}
              %
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "heightOfLayers",
    "totalHeight",
    "numberOfLayers",
    "basecourseImage",
    "unreinforcement",
    "topLayersText",
  ],
  computed: {
    computedHeightOfLayers() {
      return this.$store.state.heightOfLayers;
    },
    computedTotalHeight() {
      if (this.unreinforcement) {
        return this.$store.state.heightOfUnreinforcementLayer;
      } else {
        return this.$store.state.heightOfLayers * this.numberOfLayers;
      }
    },
  },
};
</script>

<style>
.layerHeights {
  height: v-bind(computedHeightOfLayers);
}
.totalHeight {
  height: v-bind(computedTotalHeight);
}
</style>
