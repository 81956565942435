<template>
  <div class="bg-white p-[1rem] my-[2rem] flex flex-col gap-4">
    <div>
      <p class="text-sm">{{ $t("Base course material:") }}</p>
      <span v-if="this.$store.state.typeOfBaseCourseMaterial === '0.2'">{{
        $t("Sand, e.g 0/2 mm")
      }}</span>
      <span v-else-if="this.$store.state.typeOfBaseCourseMaterial === '0.8'">{{
        $t("Stone chipping, e.g 0/8 mm")
      }}</span>
      <span v-else-if="this.$store.state.typeOfBaseCourseMaterial === '0.32'">{{
        $t("Gravelly sand with partially crushed grains, e.g 0/32 mm")
      }}</span>
      <span v-else-if="this.$store.state.typeOfBaseCourseMaterial === '0.45'">{{
        $t("Crushed stones, e.g 0/45 mm")
      }}</span>
      <span v-else-if="this.$store.state.typeOfBaseCourseMaterial === '0.63'">{{
        $t("Crushed stones, e.g 0/63 mm")
      }}</span>
      <span v-else-if="this.$store.state.typeOfBaseCourseMaterial === '0.75'">{{
        $t("Crushed stones, e.g 0/75 mm")
      }}</span>
    </div>

    <div>
      <p class="text-sm">{{ $t("Geogrid type:") }}</p>
      <p v-if="this.$store.state.numberOfLayers > 1">
        {{ topLayersText }}
      </p>
      <p>
        {{ this.$store.state.reinforcementMaterialText }}
      </p>
    </div>
    <div>
      <p class="text-sm">{{ $t("Unreinforced layer thickness:") }}</p>
      <p>{{ this.$store.state.unreinforced }} cm</p>
    </div>
    <div>
      <p class="text-sm">{{ $t("Reinforced layer thickness:") }}</p>
      <p>{{ this.$store.state.reinforced }} cm</p>
    </div>
    <h3 class="font-semibold">
      {{
        $t(
          "Note: The calculation is only valid upon the use of Secugrid / Combgrid."
        )
      }}
    </h3>
  </div>
</template>

<script>
export default {
  props: ["topLayersText"],
};
</script>
