<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    class="text-primary"
  >
    <path
      fill="currentColor"
      d="M18 3.5c1.3807 0 2.5 1.1193 2.5 2.5v12c0 1.3807-1.1193 2.5-2.5 2.5H6c-1.3807 0-2.5-1.1193-2.5-2.5V6c0-1.3807 1.1193-2.5 2.5-2.5Zm-6.5 9h-7V18c0 .7797.5949 1.4205 1.3555 1.4931L6 19.5h5.5v-7Zm8 0h-7v7H18c.7797 0 1.4205-.5949 1.4931-1.3555L19.5 18v-5.5ZM7.2236 14.528l.0693.0578L8 15.293l.7071-.7072a.5.5 0 0 1 .765.6378l-.0579.0693L8.707 16l.7072.7071a.5.5 0 0 1-.6378.765l-.0693-.0579L8 16.707l-.7071.7072a.5.5 0 0 1-.765-.6378l.0579-.0693L7.293 16l-.7072-.7071a.5.5 0 0 1 .6378-.765ZM17.5 16.5a.5.5 0 0 1 .0899.992L17.5 17.5h-3a.5.5 0 0 1-.0899-.992L14.5 16.5h3Zm0-2a.5.5 0 0 1 .0899.992L17.5 15.5h-3a.5.5 0 0 1-.0899-.992L14.5 14.5h3Zm-6-10H6c-.8284 0-1.5.6716-1.5 1.5v5.5h7v-7Zm6.5 0h-5.5v7h7V6c0-.7797-.5949-1.4205-1.3555-1.4931L18 4.5ZM8 6a.5002.5002 0 0 1 .492.4101L8.5 6.5v1h1a.5.5 0 0 1 .0899.992L9.5 8.5h-1v1a.5.5 0 0 1-.992.0899L7.5 9.5v-1h-1a.5.5 0 0 1-.0899-.992L6.5 7.5h1v-1A.5.5 0 0 1 8 6Zm9.5 1.5a.5.5 0 0 1 .0899.992L17.5 8.5h-3a.5.5 0 0 1-.0899-.992L14.5 7.5h3Z"
    ></path>
  </svg>
</template>
