<template>
  <div
    class="flex gap-[0.5rem] px-[4rem] text-primary items-center max-w-[20rem]"
  >
    Language:
    <CustomButton title="EN" @click="$i18n.locale = 'EN'" class="w-[50px]" />
    <CustomButton title="DE" @click="$i18n.locale = 'DE'" class="w-[50px]" />
  </div>
  <MainCard>
    <template #calculator>
      <router-view> </router-view>
    </template>
    <template #navbar>
      <router-view name="navigator"></router-view>

      <AdviceCard />
    </template>
  </MainCard>
</template>

<script>
import MainCard from "./layouts/MainCard.vue";
import AdviceCard from "./layouts/AdviceCard.vue";
import CustomButton from "./components/CustomButton.vue";
export default {
  components: {
    MainCard,
    AdviceCard,
    CustomButton,
  },
};
</script>

<style></style>
