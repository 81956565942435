<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="25"
    class="text-primary"
    :class="classes"
  >
    <path
      fill="currentColor"
      d="M20.6678 7.6263a.5.5 0 0 1 .7262.6817l-.0618.0657-9 8a.5001.5001 0 0 1-.5892.0553l-.0752-.0553-9-8a.5.5 0 0 1 .5919-.801l.0725.0536L12 15.33l8.6678-7.7037Z"
    ></path>
  </svg>
</template>
<script>
export default {
  props: ["classes"],
};
</script>
