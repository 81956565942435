<template>
  <div class="w-full my-4">
    <button
      class="bg-primary text-white w-full rounded-xl h-14 flex items-center justify-center gap-2 hover:text-primary hover:bg-white hover:border-[1px] hover:border-primary transition duration-1000"
    >
      <slot></slot>
      <div class="font-bold">{{ title }}</div>
    </button>
  </div>
</template>
<script>
export default {
  props: ["title"],
};
</script>
