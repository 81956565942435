<template>
  <div
    class="group border-[1px] border-primary rounded-[0.7rem] p-[1rem] group-hover:bg-primary"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="30"
      data-v-042d627d=""
    >
      <path
        class="group-hover:fill-white"
        fill="rgb(43, 173, 112)"
        fill-rule="nonzero"
        d="M13 2.5a2.5 2.5 0 0 1 2.45 2H17a2.5 2.5 0 0 1 2.495 2.336L19.5 7v12a2.5 2.5 0 0 1-2.336 2.495L17 21.5H7a2.5 2.5 0 0 1-2.495-2.336L4.5 19V7a2.5 2.5 0 0 1 2.336-2.495L7 4.5h1.55a2.501 2.501 0 0 1 2.286-1.995L11 2.5h2Zm-4.45 3H7a1.5 1.5 0 0 0-1.493 1.356L5.5 7v12a1.5 1.5 0 0 0 1.356 1.493L7 20.5h10a1.5 1.5 0 0 0 1.493-1.356L18.5 19V7a1.5 1.5 0 0 0-1.356-1.493L17 5.5h-1.55a2.501 2.501 0 0 1-2.286 1.995L13 7.5h-2a2.5 2.5 0 0 1-2.45-2Zm6.45 10a.5.5 0 0 1 .09.992L15 16.5h-3a.5.5 0 0 1-.09-.992L12 15.5h3Zm0-4a.5.5 0 0 1 .09.992L15 12.5h-3a.5.5 0 0 1-.09-.992L12 11.5h3Zm-2-8h-2a1.5 1.5 0 0 0-1.493 1.356L9.5 5a1.5 1.5 0 0 0 1.356 1.493L11 6.5h2a1.5 1.5 0 0 0 .144-2.993L13 3.5Z"
      ></path>
    </svg>
  </div>
</template>
