<template>
  <div
    class="bg-[#FCF7E7] text-[#8c6920] flex rounded-xl p-[1rem] items-start gap-[1rem] my-[1rem]"
  >
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="22"
        class="hd-icon"
        data-v-7edfd7e0=""
        fill="#8c6920"
      >
        <path
          d="M9.8193 4.75c.9692-1.6667 3.3922-1.6667 4.3614 0l6.9782 12c.9692 1.6666-.2424 3.75-2.1807 3.75H5.0218c-1.9384 0-3.1499-2.0834-2.1807-3.75ZM12 15a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm0-5a.5.5 0 0 0-.5.5v3l.008.0899A.5002.5002 0 0 0 12.5 13.5v-3l-.008-.0899A.5002.5002 0 0 0 12 10Z"
        ></path>
      </svg>
    </div>

    <slot></slot>
  </div>
</template>
